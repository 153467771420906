import React, { useEffect, useMemo, useState } from "react";
import { styled } from "goober";

import CompPreview from "@/game-tft/CompPreview.jsx";
import { autoScale } from "@/game-tft/InGameShared.jsx";
import { cssTierIcon } from "@/game-tft/OverviewChampions.style.jsx";
import fetchCompsClusterData from "@/game-tft/tft-fetch-comps-cluster.mjs";
import getWinRateColor from "@/game-tft/util-get-winrate-color.mjs";
import getTierIcon from "@/shared/get-tier-icon.mjs";

export default function InGameAugmentsOverlay({
  t,
  augments,
  augmentStatic,
  augmentStats,
  localizedAugments,
  captureData,
  pinnedComp,
  set,
  patch,
  isTFTCompsOverlayEnabled,
  roundStage = 0,
  queue,
  offeredAugments = [],
  setOfferedAugments,
}) {
  const setAugments = useMemo(
    () => getAugmentArray(augmentStatic),
    [augmentStatic],
  );
  const localizedAugmentsArray = useMemo(
    () => getAugmentArray(localizedAugments),
    [localizedAugments],
  );
  const [foundAugments, setFoundAugments] = useState([]);
  const [compAugmentStats, setCompAugmentStats] = useState();

  // tracking
  useEffect(() => {
    captureData({
      name: "augment info",
      action: "view",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !setAugments ||
      !Object.keys(setAugments).length ||
      !Array.isArray(augments) ||
      !augments?.length
    )
      return;

    const augmentArray = [];
    const sortedAugments = augments.sort((a, b) => a.position.x - b.position.x);
    sortedAugments.forEach((aug, i) => {
      let isLegendAugment = false;
      const iconFromStaticData = ([_, data]) => {
        const icon = aug.name?.split("@")[0];
        if (aug.name?.split("@")[1]) {
          isLegendAugment = true;
        }
        return data.icon === icon;
      };

      let foundAugment;
      const augmentFromIcon =
        Object.entries(setAugments).filter(iconFromStaticData);

      // Use resource name if icon is not found
      if (!augmentFromIcon?.length || augmentFromIcon?.length > 1) {
        const nameEqualsResource = ([_, data]) => {
          return (
            data.name.replace(/[^A-Z0-9]/gi, "").toLowerCase() ===
            aug.resource?.replace(/[^A-Z0-9]/gi, "").toLowerCase()
          );
        };

        const augmentFromResource = Object.entries(setAugments)
          .filter(([apiKey, _]) =>
            isLegendAugment
              ? apiKey.includes("_Legend_")
              : !apiKey.includes("_Legend_"),
          )
          .find(nameEqualsResource);
        foundAugment = augmentFromResource?.[0];

        // If not found, try localized augments
        if (!foundAugment && localizedAugmentsArray) {
          foundAugment = Object.entries(localizedAugmentsArray).find(
            nameEqualsResource,
          )?.[0];
        }

        // fix for augments with same icons and names
        if (augmentFromIcon?.length > 1 && i === 0) {
          for (const [apiKey, _] of augmentFromIcon) {
            if (apiKey.includes("_Legend_") && isLegendAugment) {
              foundAugment = apiKey;
            }
          }
        }
      } else {
        foundAugment = augmentFromIcon[0][0];
      }

      if (foundAugment) {
        augmentArray.push({
          apiName: foundAugment,
          stats: augmentStats?.[foundAugment],
          position: aug.position,
        });
        if (!offeredAugments.includes(foundAugment))
          setOfferedAugments([...offeredAugments, foundAugment]);
      }
    });

    const bestPick = [...augmentArray].sort(
      (a, b) =>
        a.stats?.tier * 100 +
        a.stats?.avg_placement -
        (b.stats?.tier * 100 + b.stats?.avg_placement),
    )[0];
    const bestPickAugment = augmentArray.find(
      (augment) => augment.apiName === bestPick.apiName,
    );
    if (bestPickAugment) bestPickAugment.highlighted = true;

    setFoundAugments(augmentArray);
  }, [
    augmentStats,
    augments,
    localizedAugmentsArray,
    offeredAugments,
    setAugments,
    setOfferedAugments,
  ]);

  // Pinned Comp Stats
  useEffect(() => {
    if (typeof pinnedComp?.clusterId === "number" && foundAugments?.length) {
      const page = pinnedComp.isEarly ? "earlycompDetails" : "compDetails";

      const options = {
        clusterId: pinnedComp.clusterId,
        set,
        patch,
        filters: `rank=PLATINUM+&region=WORLD&mode=RANKED&cluster_id=${pinnedComp.clusterId}`,
        tab: "augments",
        isEarlyComp: pinnedComp.isEarly,
        page,
        options: {
          networkBackOffTime: 1000 * 60 * 60, // 1 hour
          skipSafetyCheck: true,
        },
      };
      fetchCompsClusterData(options).then((d) => {
        setCompAugmentStats(
          foundAugments.map((augment) => {
            const apiName = augment.apiName;
            const result = d.find(
              (aug) => aug.name?.toLowerCase() === apiName?.toLowerCase(),
            );
            const pick_rate = result?.pick_rate;
            const avg_placement = result?.avg_placement;
            return { ...augment, compStats: { pick_rate, avg_placement } };
          }),
        );
      });
    }
  }, [foundAugments, patch, pinnedComp?.clusterId, pinnedComp?.isEarly, set]);

  if (
    !setAugments ||
    !Object.keys(setAugments).length ||
    !Array.isArray(augments) ||
    !augments?.length ||
    !foundAugments?.length
  )
    return null;
  const result = compAugmentStats || foundAugments;

  return result.map((augment, i) => (
    <AugmentInfo
      augment={augment}
      x={augment.position.x}
      y={augment.position.y}
      scale={autoScale()}
      t={t}
      key={i}
      isTFTCompsOverlayEnabled={isTFTCompsOverlayEnabled}
      set={set}
      roundStage={roundStage}
      queue={queue}
    />
  ));
}

const AugmentInfo = ({
  augment,
  x,
  y,
  scale = 1,
  t,
  avgPickRate,
  isTFTCompsOverlayEnabled,
  set,
  roundStage,
  queue,
}) => {
  const TierIcon = augment.stats?.tier && getTierIcon(augment.stats?.tier);
  const topComps = augment.stats?.top_comps;
  return (
    <AugmentInfoContainer
      className={augment.highlighted ? "highlighted" : ""}
      style={{
        left: x.toFixed(2) + "px",
        top: y.toFixed(2) + "px",
        transform: `scale(${scale}) translate(-50%, -30%)`,
      }}
    >
      <table>
        <thead>
          <tr>
            <th></th>
            <th>{t("tft:pickRate", "Pick Rate")}</th>
            <th>{t("common:avgPlace", "Avg. Place")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className="label type-overline">
              {t("common:general", "General")}
            </th>
            <td>
              <div
                className="value"
                style={{
                  color: getWinRateColor(augment.stats?.pick_rate, avgPickRate),
                }}
              >
                {augment.stats?.pick_rate
                  ? (augment.stats.pick_rate * 100)?.toFixed(0) + "%"
                  : "-"}
              </div>
            </td>
            <td>
              <div
                className="value"
                style={{
                  color: getWinRateColor(
                    augment.stats?.avg_placement,
                    4.5,
                    true,
                  ),
                }}
              >
                {augment.stats?.avg_placement
                  ? augment.stats.avg_placement?.toFixed(1)
                  : "-"}
              </div>
            </td>
          </tr>
          {isTFTCompsOverlayEnabled ? (
            <tr>
              <th className="label type-overline">
                {t("tft:pinnedComp", "Pinned Comp")}
              </th>
              <td>
                <div
                  className="value"
                  style={{
                    color: getWinRateColor(
                      augment.compStats?.pick_rate,
                      avgPickRate,
                    ),
                  }}
                >
                  {augment.compStats?.pick_rate
                    ? (augment.compStats.pick_rate * 100)?.toFixed(0) + "%"
                    : "-"}
                </div>
              </td>
              <td>
                <div
                  className="value"
                  style={{
                    color: getWinRateColor(
                      augment.compStats?.avg_placement,
                      4.5,
                      true,
                    ),
                  }}
                >
                  {augment.compStats?.avg_placement
                    ? augment.compStats.avg_placement?.toFixed(1)
                    : "-"}
                </div>
              </td>
            </tr>
          ) : (
            ""
          )}
        </tbody>
      </table>

      <div className="tier flex align-center">
        <div>
          {augment.stats?.tier ? <TierIcon className={cssTierIcon()} /> : "-"}
        </div>
        <div>{t("lol:tier", "Tier")}</div>
      </div>

      {(parseInt(roundStage) === 1 ||
        parseInt(roundStage) === 2 ||
        (queue === "TEAMFIGHT_TACTICS_HYPER_ROLL" &&
          parseInt(roundStage) === 3)) &&
      topComps?.length ? (
        <div className="bestComps">
          <div>{t("common:suggested", "Suggested")}</div>
          {topComps?.map((cluster_id) => (
            <CompPreview clusterId={cluster_id} set={set} key={cluster_id} />
          ))}
        </div>
      ) : (
        ""
      )}
    </AugmentInfoContainer>
  );
};

const getAugmentArray = (data) => {
  return {
    ...data?.["hero"],
    ...data?.["tier1"],
    ...data?.["tier2"],
    ...data?.["tier3"],
  };
};

const AugmentInfoContainer = styled("div")`
  background: var(--shade10-50);
  color: white;
  font-size: 0.875rem;
  font-weight: bold;
  border-radius: var(--sp-3);
  box-shadow: var(--sp-0) var(--sp-1) var(--sp-1) rgba(0, 0, 0, 0.25);
  line-height: var(--sp-4_5);
  position: fixed;
  transform-origin: top left;
  width: 260px;
  z-index: 1;
  box-sizing: border-box;
  padding: var(--sp-1) var(--sp-3);

  > * {
    pointer-events: none;
  }

  &.highlighted {
    border: 3px solid #ac9052;
    box-sizing: content-box;
  }

  table {
    border-spacing: var(--sp-1) 0;
    border: transparent;
    width: 100%;
  }

  table td,
  table th {
    font-size: var(--sp-3_5);
    line-height: var(--sp-7);
    text-align: center;

    .value {
      font-size: var(--sp-4);
      line-height: var(--sp-6);
    }
  }

  table .label {
    text-align: left;
    color: var(--shade0-50);
    max-width: 5rem;
    line-height: var(--sp-4);
  }

  .tier {
    position: absolute;
    top: calc(var(--sp-2_5) * -1);
    left: 0;
    background: var(--shade6);
    border-radius: 0px var(--br) var(--br) 0px;
    padding: var(--sp-1) var(--sp-2);
    padding-right: var(--sp-4);
  }

  &.highlighted .tier {
    border: 3px solid #ac9052;
    left: calc(var(--sp-px) * -3);
  }

  .bestComps {
    position: absolute;
    top: 0;
    right: var(--sp-2);
    transform: translate(100%, 0);
    background: var(--shade6);
    border-radius: var(--sp-3);
    border-top-left-radius: 0;
    padding: var(--sp-2);
    display: flex;
    flex-direction: column;
    gap: var(--sp-2);
    align-items: center;
    pointer-events: auto;
  }

  &.highlighted .bestComps {
    top: calc(var(--sp-1_5) * -1);
    border: 3px solid #ac9052;
  }
`;
